
const api = {
    backendUrl: null,
    token: null,

    formatUrl: function(path){
        if(path.startsWith('http://') ||  path.startsWith('https://')){
            return new URL(path)
        }
        if (path[0] !== '/') {
            path = '/' + path
        }
        return new URL(this.backendUrl + path)
    },

    async get(path, querySpec, mode="json") {
        console.log(this.token)
        const url = this.formatUrl(path)
        if (querySpec != null) {
            url.search = new URLSearchParams(this.clean(querySpec)).toString()
        }
        try {
            let req = await fetch(url.toString(), {
                method: 'GET', headers: this.token ? {
                    'Authorization': `Bearer ${this.token}`,
                } : {}

            })
            if (req.ok) {
                let payload
                if(mode === "json"){
                    payload = await this.tryGetJson(req)
                } else if (mode === "blob"){
                    payload = await req.blob()
                }
                return { ok: true, payload: payload }
            } else {
                console.log(req)
                return { ok: false, payload: null, error: req.err }
            }
        } catch (e) {
            console.log(e)
            return { ok: false, error: e }
        }
    },

    async del(path, querySpec) {
        const url = this.formatUrl(path)
        if (querySpec != null) {
            url.search = new URLSearchParams(this.clean(querySpec)).toString()
        }
        try {
            let req = await fetch(url.toString(), {
                method: 'DELETE', headers: this.token ? {
                    'Authorization': `Bearer ${this.token}`,
                } : {}

            })
            if (req.ok) {
                let payload
                return { ok: true, payload: payload }
            } else {
                console.log(req)
                return { ok: false, payload: null, error: req.err }
            }
        } catch (e) {
            console.log(e)
            return { ok: false, error: e }
        }
    },

    async postJson(path, obj, mode='json') {
        const url = this.formatUrl(path)
        try {
            let req = await fetch(url.toString(), {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${this.token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(obj)
            })
            if (req.ok) {
                let payload = null
                if(mode === "json"){
                    payload = await this.tryGetJson(req)
                } else if (mode === "blob"){
                    payload = await req.blob()
                }
                return { ok: true, payload: payload }
            } else {
                console.log(req)
                return { ok: false, payload: null, error: req.err ? req.err : await req.text() }
            }
        } catch (e) {
            console.log(e)
            return { ok: false, error: e }
        }
    },

    async putJson(path, obj) {
        const url = this.formatUrl(path)
        try {
            let req = await fetch(url.toString(), {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${this.token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(obj)
            })
            if (req.ok) {
                return { ok: true, payload: await this.tryGetJson(req) }
            } else {
                console.log(req)
                return { ok: false, payload: null, error: req.err ? req.err : await req.text() }
            }
        } catch (e) {
            console.log(e)
            return { ok: false, error: e }
        }
    },

    async postFormData(path, formData) {
        const url = this.formatUrl(path)

        //На вход удобно также получать не форм дата, а объект
        if (!(formData instanceof FormData)) {
            let correctFormData = new FormData();

            for (let key in formData) {
                correctFormData.append(key, formData[key]);
            }
            formData = correctFormData
        }

        let req = await fetch(url.toString(), {
            method: 'POST', headers: {
                'Authorization': `Bearer ${this.token}`,
            }, body: formData
        })
        if (req.ok) {
            return { ok: true, payload: await this.tryGetJson(req) }
        } else {
            console.log(req)
            return { ok: false, payload: null }
        }
    },

    async tryGetJson(req) {
        let result = null
        try {
            result = await req.json()
        } catch (e) {
            result = null
        }
        return result
    },

    clean(obj) {
        for (let propName in obj) {
            if (obj[propName] === null || obj[propName] === undefined) {
                delete obj[propName]
            }
        }
        return obj
    }
}

export default api
