<template>
  <v-app id="app">
    <header>
      <div>
        <div class="wrap">
          <a href="/" id="logo"><img src="./assets/logo.svg" alt=""></a>

          <div class="date">{{date | dateFormat}}</div>


          <v-menu style="max-width: 300px" offset-y>
            <template v-slot:activator="{ on }">
              <div class="user" v-on="on">
                <p>{{$user.lastName}} {{$user.firstName[0]}}. {{$user.middleName[0]}}.</p>
                <div>{{$user.userName[0]}}{{$user.userName[1]}}</div>
              </div>
            </template>
            <v-card>
              <v-list dense>
                <v-list-item-group color="white">
                  <v-list-item @click="logout()">
                    <v-list-item-icon>
                      <v-icon>mdi-exit-to-app</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Выход</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-menu>


        </div>
      </div>
    </header>

    <main>
    <router-view/>
    </main>

  </v-app>
</template>

<style lang="scss">
@import "./styles/main.scss";
</style>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

</style>



<script>
export default {
  props: {
  },
  data() {
    return {
      date: new Date()
    };
  },
  filters: {
    dateFormat: function (date) {
      if (date == null) {
        return ''
      }
      const mdate = new Date(date)
      const options = {
        timeZone: 'Europe/Moscow',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        weekday: 'short'
      }
      return mdate.toLocaleDateString('ru-RU', options)
    }
  },
    methods: {
        logout() {
            this.$keycloak.logout()
        }
    },
  beforeMount() {
    setInterval(()=>{
      this.date = new Date()
    }, 1000)
  }

};
</script>
