import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
  },
  {
    path: '/directories',
    name: 'Directories',
    component: () => import('../views/Directories.vue'),
  },
  {
    path: '/calendar',
    name: 'Calendar',
    component: () => import('../views/Calendar.vue'),
  },
  {
    path: '/directory1',
    name: 'Directory1',
    component: () => import('../views/Directory1.vue'),
  },
  {
    path: '/directory2',
    name: 'Directory2',
    component: () => import('../views/Directory2.vue'),
  },
  {
    path: '/archive',
    name: 'Archive',
    component: () => import('../views/Archive.vue'),
  },
  {
    path: '/new',
    name: 'New',
    component: () => import('../views/New.vue'),
  },
  {
    path: '/task1/:id',
    name: 'task1',
    component: () => import('../views/Task1.vue'),
  },
  {
    path: '/task2',
    name: 'task2',
    component: () => import('../views/Task2.vue'),
  },
  {
    path: '/task3',
    name: 'task3',
    component: () => import('../views/Task3.vue'),
  },
  {
    path: '/task4',
    name: 'task4',
    component: () => import('../views/Task4.vue'),
  },
  {
    path: '/assessment/:id',
    name: 'assessment',
    component: () => import('../views/AssessmentView.vue'),
  },


]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
