import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import api from "@/modules/api";
import Keycloak from 'keycloak-js'

//api.backendUrl = 'http://localhost:8099/api'

api.backendUrl = 'https://edu.czn-office.ru/api'




Vue.config.productionTip = false

let initOptions = {
    url: 'https://auth.czn-office.ru/',
    realm: 'Eduroute',
    clientId: 'edu',
    onLoad: 'login-required',
}
let keycloak = Keycloak(initOptions)
Vue.prototype.$keycloak = keycloak


keycloak.init({ onLoad: initOptions.onLoad, checkLoginIframe: false }).then(async (auth) => {
    if (!auth) {
        window.location.reload()
    } else {
        api.token = keycloak.token
        console.log(keycloak.tokenParsed)
        Vue.prototype.$user = {
            userName: keycloak.tokenParsed.preferred_username,
            firstName: keycloak.tokenParsed.given_name,
            middleName: keycloak.tokenParsed.middle_name,
            lastName: keycloak.tokenParsed.family_name
        }
        /* eslint-disable no-new */
        new Vue({
            el: '#app',
            render: h => h(App, {props: {keycloak: keycloak}}),
            vuetify,
            router
        })
    }

    // Token Refresh
    setInterval(() => {
        keycloak.updateToken(70).then((refreshed) => {
            if (refreshed) {
                api.token = keycloak.token
                // console.log('Token not refreshed, valid for ' + Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds')
            }
        }).catch(() => {
            console.log('Failed to refresh token')
        })
    }, 6000)
}).catch((e) => {
    console.log('Authentication Failed: ' + e)
})
